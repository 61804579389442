import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import WordCounter from './pages/wordcounter/Wordcounter';
import ReactGA from "react-ga4";


function App() {

  const TRACKING_ID = 'G-YK6QMK7FNL';
  ReactGA.initialize(TRACKING_ID);
  // ReactGA.send({ hitType: "pageview", page: "/" });
  ReactGA._gaCommandSendPageview(document.location.pathname)

  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<WordCounter />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;

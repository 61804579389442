import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './wordcounter.css'
import logo from './logo192.PNG'
import clear from './cancel.svg'
import smaller from './lower.svg'
import capital from './caps.svg'
import titlecase from './titlecase.svg'
import removespace from './removespace.svg'
import copytext from './copytext.svg'
import fblogo from './fblogo.svg'
import twitterlogo from './twitterlogo.svg'
import instalogo from './instalogo.svg'
import tiktoklogo from './tiktoklogo.svg'
import snapchatlogo from './snapchatlogo.svg'
import ytlogo from './ytlogo.svg'


const WordCounter = () => {

    const [text, setText] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [letterCount, setLetterCount] = useState(0);
    const [letternospaceCount, setNospaceLetterCount] = useState(0);
    const [sentenceCount, setSentenceCount] = useState(0);
    const [paragraphCount, setParagraphCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [readingTime, setReadingTime] = useState(0);
    const [speakingTime, setSpeakingTime] = useState(0);
    const [wordFrequency, setWordFrequency] = useState({});

    useEffect(() => {
        countWords(text);
    }, [text]);

    const handleInputChange = (event) => {
        setText(event.target.value);
    };

    const countWords = (inputText) => {
        const words = inputText.trim().split(/\s+/);
        // const words = text.trim().split(' ');
        const filteredWords = words.filter((word) => word !== '');
        const sentences = inputText.split(/[.!?]+/).filter((sentence) => sentence.trim() !== '');
        const paragraphs = inputText.split(/\n{1,}/).filter((paragraph) => paragraph.trim() !== '');

        setWordCount(filteredWords.length);
        setNospaceLetterCount(text.replace(/ /g, '').length);
        setLetterCount(text.length);
        setSentenceCount(sentences.length);
        setParagraphCount(paragraphs.length);

        const wordsPerPage = 250; // Assuming an average of 250 words per page
        setPageCount(Math.ceil(filteredWords.length / wordsPerPage));

        const avgReadingSpeed = 275; // Assuming an average reading speed of 200 words per minute
        setReadingTime(Math.ceil(filteredWords.length / avgReadingSpeed));

        const avgSpeakingSpeed = 180; // Assuming an average speaking speed of 125 words per minute
        setSpeakingTime(Math.ceil(filteredWords.length / avgSpeakingSpeed));

        const frequency = {};
        words.forEach((word) => {
            frequency[word] = (frequency[word] || 0) + 1;
        });
        setWordFrequency(frequency);

    };


    const clearText = () => {
        setText("");
    };

    const toTitleCase = () => {
        setText(text.toLowerCase().replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, i => i.toUpperCase()))
    };

    const caseUpper = () => {
        setText(text.toUpperCase());
    };

    const caseLower = () => {
        setText(text.toLowerCase());
    };

    const toremoveWhitespace = () => {
        setText(text.trim());
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div className='counter-main'>
            <HelmetProvider>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Word Counter Online</title>
                    <meta name='description' content='Count Words and Characters of your text and content online' />
                </Helmet>
            </HelmetProvider>

            {/* Word Counter */}
            <div className='counter-window'>

                <div className='logo-title'>
                    <Link to="/">
                        <img src={logo} className='mainlogo'>
                        </img>
                    </Link>
                    <h2>Word Counter Online</h2>
                </div>

                <div className='counter-output-social-count'>
                    <div className='counter-output'>

                        <h4 className='h4-tag'>Words : {wordCount}</h4>
                        <h4 className='h4-tag'>Characters : {letterCount}</h4>
                        {/* <h4>Letter without space Count: {letternospaceCount} </h4>*/}
                        <h4 className='h4-tag'>Sentences : {sentenceCount}</h4>
                        <h4 className='h4-tag'>Paragraphs : {paragraphCount}</h4>
                        <h4 className='h4-tag'>Pages : {pageCount}</h4>
                        <h4 className='h4-tag'>Reading Time: {readingTime} min.</h4>
                        <h4 className='h4-tag'>Speaking Time: {speakingTime} min.</h4>

                        {/* <div>
                    <h3>Word Frequency Count:</h3>
                    <ul>
                        {Object.entries(wordFrequency).map(([word, count]) => (
                            <li key={word}>
                                {word}: {count}
                            </li>
                        ))}
                    </ul>
                </div> */}
                    </div>



                </div>

                <div className='main-transform-buttons'>

                    <div className='text-transform-buttons'>
                        <button className='clear-text' onClick={clearText}>
                            <img src={clear} className='svglogo' />
                            Clear Text</button>
                        <button className='clear-text' onClick={caseUpper}><img src={capital} className='svglogo' />Upper Case</button>
                        <button className='clear-text' onClick={caseLower}><img src={smaller} className='svglogo' />Lower Case</button>
                        {/* <button className='clear-text' onClick={toTitleCase}>Title Case</button> */}
                    </div>

                    <button className='copy-text' onClick={handleCopyClick}><img src={copytext} className='svglogo' />Copy Text</button>

                </div>

                {/* <div className='main-transform-buttons'>

                    <div className='text-transform-buttons'>
                        <button className='clear-text' onClick={clearText}>Clear Text</button>
                        <button className='clear-text' onClick={caseUpper}>Upper Case</button>
                        <button className='clear-text' onClick={caseLower}>Lower Case</button>
                        <button className='clear-text' onClick={toTitleCase}>Title Case</button>
                    </div>

                    <button className='copy-text' onClick={handleCopyClick}>Copy Text</button>

                </div> */}

                <div className='main-transform-buttons'>

                    <div className='text-transform-buttons'>
                        {/* <button className='clear-text' onClick={clearText}>Clear Text</button>
                        <button className='clear-text' onClick={caseUpper}>Upper Case</button>
                        <button className='clear-text' onClick={caseLower}>Lower Case</button> */}
                        <button className='clear-text' onClick={toTitleCase}><img src={titlecase} className='svglogo' />Title Case</button>
                        <button className='clear-text' onClick={toremoveWhitespace}><img src={removespace} className='svglogo' />Removes whitespace from both ends</button>
                    </div>

                    {/* <button className='copy-text' onClick={handleCopyClick}>Copy Text</button> */}

                </div>

                <textarea className='counter-text-input-box'
                    value={text}
                    onChange={handleInputChange}
                    placeholder="Enter your text..."
                ></textarea>

                <div className='social-counter-output'>
                    <h4 className={letterCount >= 280 ? "h4-tag-limit" : "h4-tag"}><img src={twitterlogo} className='sociallogo' />Twitter : {280 - letterCount}/280</h4>
                    <h4 className={letterCount >= 250 ? "h4-tag-limit" : "h4-tag"}><img src={fblogo} className='sociallogo' />Facebook : {250 - letterCount}/250</h4>
                    <h4 className={letterCount >= 100 ? "h4-tag-limit" : "h4-tag"}><img src={ytlogo} className='sociallogo' />YouTube Title : {100 - letterCount}/100</h4>
                    <h4 className={letterCount >= 125 ? "h4-tag-limit" : "h4-tag"}><img src={instalogo} className='sociallogo' />Instagram Cap. : {125 - letterCount}/125</h4>
                    <h4 className={letterCount >= 300 ? "h4-tag-limit" : "h4-tag"}><img src={tiktoklogo} className='sociallogo' />TikTok Cap. : {300 - letterCount}/300</h4>
                    <h4 className={letterCount >= 80 ? "h4-tag-limit" : "h4-tag"}><img src={snapchatlogo} className='sociallogo' />Snapchat : {80 - letterCount}/80</h4>
                    <p className='h4-tag'>*Cap. - Caption</p>
                </div>

            </div>

        </div >
    )
}

export default WordCounter